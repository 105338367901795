<template>
  <AuthWrapper>
    <div class="auth-contents auth-sign-in">
      <a-form @finish="handleSubmit" :model="formState" :rules="rules" layout="vertical" class="auth-container">
        <sdHeading as="h3" class="sign-in__header">
          {{ i18n.t('authPage.signIn') }}
          <a-select
            class="sign-in__language"
            v-model:value="currentLanguage"
            @change="changeLanguage"
            :options="languages"
            ref="select"
          >
          </a-select>
        </sdHeading>
        <a-form-item name="email" ref="email" :label="i18n.t('authPage.email')">
          <a-input type="email" v-model:value="formState.email" />
        </a-form-item>
        <a-form-item name="password" ref="password" :label="i18n.t('authPage.password')">
          <a-input-password v-model:value="formState.password" />
        </a-form-item>
        <div class="auth-form-action auth-pc">
          <router-link class="forgot-pass-link" :to="{ name: 'forgotPassword' }">
            {{ i18n.t('authPage.forgotPassword') }}
          </router-link>
          <router-link class="forgot-pass-link" :to="{ name: 'resendCode' }">
            {{ i18n.t('authPage.resendConfirmCodeAgain') }}
          </router-link>
        </div>
        <a-form-item>
          <div class="auth-form-action">
            <sdButton class="btn-signin" htmlType="submit" :disabled="isLoading" type="primary" size="large">
              {{ i18n.t('authPage.signInButton') }}
            </sdButton>
            <p class="sign-in__to-register">
              <router-link :to="{ name: 'register' }">
                {{ i18n.t('authPage.signUp') }}
              </router-link>
            </p>
          </div>
        </a-form-item>

        <div class="auth-form-action auth-mobile">
          <router-link class="forgot-pass-link" :to="{ name: 'forgotPassword' }">
            {{ i18n.t('authPage.forgotPassword') }}
          </router-link>
          <router-link class="forgot-pass-link" :to="{ name: 'resendCode' }">
            {{ i18n.t('authPage.resendConfirmCodeAgain') }}
          </router-link>
        </div>
        <sdModal
          :visible="visible"
          centered
          :title="i18n.t('global.2FacChange')"
          :onCancel="changeVisibleModal"
          :width="320"
        >
          <template #footer>
            <a-button key="SignIn" type="primary" @click="handleSubmitTwoFac">{{
              i18n.t('authPage.signInButton')
            }}</a-button>
          </template>
          <a-form-item name="twoFac" :label="i18n.t('authPage.TwoFacTitle')" style="display: block">
            <a-input
              type="number"
              style="width: 100%; height: 50px"
              @keyup.enter="handleSubmitTwoFac"
              v-model:value="formState.code"
            />
          </a-form-item>
          <div class="qr-code__wrapper">
            <sdButton type="danger" outlined @click="changeVisibleModal">
              {{ i18n.t('transactions.cancel', 2) }}
            </sdButton>
            <sdButton type="success" outlined @click="handleSubmitTwoFac" :disabled="isLoading">
              {{ i18n.t('authPage.signIn') }}
            </sdButton>
          </div>
        </sdModal>
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script>
import { computed, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { AuthWrapper } from './style';
import { useRouter } from 'vue-router';
import User from '@/models/user';
import { useI18n } from 'vue-i18n';
import { message } from 'ant-design-vue';
import { languages } from '@/config/helpers/JSONdata.js';
import helper from '@/config/helpers/helper';
import { getCookie, setCookie } from '@/utility/localStorageControl';

const SignIn = {
  name: 'SignIn',
  components: { AuthWrapper },
  setup() {
    const i18n = useI18n();
    const { state, dispatch } = useStore();
    const router = useRouter();

    const visible = ref(false);
    const checked = ref(null);
    const isPc = computed(() => window.innerWidth);
    const isLoading = computed(() => state.auth.loading);
    const loggedIn = computed(() => {
      return state.auth?.status?.loggedIn ? this.$router.push('/') : true;
    });
    const currentLanguage = ref(getCookie('language'));

    const emailValidator = async (rule, value) => {
      if (!rules.email[0].trigger[1]) {
        rules.email[0].trigger[1] = 'change';
      }
      if (formState.email == '') {
        return Promise.reject(i18n.t('authPage.errorEmail'));
      }

      const errorMsg = helper().checkUserMail({ email: value });
      if (errorMsg !== true) {
        return Promise.reject(i18n.t(errorMsg, { email: formState.email }));
      } else {
        return Promise.resolve();
      }
    };
    const rules = reactive({
      email: [
        {
          validator: emailValidator,
          trigger: ['blur'],
        },
      ],
    });
    const handleSubmit = () => {
      if (formState.email && formState.password) {
        formState.locale = getCookie('language');
        dispatch('auth/login', formState).then(
          (res) => {
            if (res.success) {
              changeVisibleModal();
            } else {
              router.go(0);
            }
          },
          (error) => {
            message.error((error.response && error.response.data.error) || error.message || error.toString());
          },
        );
      }
    };
    const handleSubmitTwoFac = () => {
      dispatch('auth/AuthSec', formState).then(
        () => {
          router.go(0);
        },
        (error) => {
          message.error((error.response && error.response.data.error) || error.message || error.toString());
        },
      );
    };
    const formState = reactive(new User('', ''));

    const changeVisibleModal = () => {
      visible.value = !visible.value;
    };

    const changeLanguage = (data) => {
      currentLanguage.value = data;
      i18n.locale.value = data;
      setCookie({ name: 'language', value: data });
    };
    return {
      isLoading,
      visible,
      i18n,
      checked,
      formState,
      loggedIn,
      languages,
      currentLanguage,
      rules,
      isPc,
      changeVisibleModal,
      handleSubmitTwoFac,
      handleSubmit,
      changeLanguage,
    };
  },
};

export default SignIn;
</script>
<style>
.qr-code__wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
